import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/icon';
import cn from 'classnames';

const ToggleButton = ({
  hint,
  isActive,
  label,
  onClick = () => {},
  amount = 0,
  ariaControls,
}) => {
  return (
    <div className="toggle-button__container">
      <div className="toggle-button__hint">
        <span className="toggle-button__hint-text">{hint}</span>
        <Icon className="toggle-button__hint-icon" name="arrow-right" />
      </div>

      <button
        onClick={onClick}
        className={cn('toggle-button', {
          'toggle-button_active': isActive,
        })}
        title={hint}
        aria-expanded={isActive}
        aria-controls={ariaControls}
      >
        <span className="toggle-button__text">
          {label} {amount > 0 ? '(' + amount + ')' : null}
        </span>
        <Icon
          name="toggle"
          className={cn('toggle-button__icon', {
            'toggle-button__icon_rotated': isActive,
          })}
        />
      </button>
    </div>
  );
};

ToggleButton.propTypes = {
  hint: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
  amount: PropTypes.number,
  ariaControls: PropTypes.string,
};

ToggleButton.propTypesMeta = 'exclude';

export default ToggleButton;
