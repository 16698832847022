import React from 'react';
import PropTypes from 'prop-types';
import LinkButton from 'components/link-button/link-button';
import useBreakpoints from 'hooks/use-breakpoints';

const RelatedItemsHeader = ({ heading, seeAllLink }) => {
  const { isDesktop } = useBreakpoints(false);
  return (
    <div className="related-items-haeder">
      {heading && <h2 className="related-items-haeder__heading">{heading}</h2>}
      {isDesktop && seeAllLink && (
        <LinkButton
          {...seeAllLink}
          className="related-items-haeder__see-all-button"
        />
      )}
    </div>
  );
};

RelatedItemsHeader.propTypes = {
  heading: PropTypes.string,
  seeAllLink: PropTypes.exact(LinkButton.propTypes),
};

RelatedItemsHeader.propTypesMeta = 'exclude';

export default RelatedItemsHeader;
