import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import scrollToElement from 'scroll-to-element';
import Icon from 'components/icon';

const ScrollButton = ({
  className,
  speed = 500,
  label,
  targetElement,
  iconName,
  ariaLabel,
}) => {
  const scrollToEl = () => {
    scrollToElement(targetElement, {
      offset: 0,
      ease: 'linear',
      duration: speed,
    });
  };

  return (
    <button
      className={cn('scroll-button', className)}
      onClick={scrollToEl}
      aria-label={ariaLabel}
    >
      {label && <span className="scroll-button__text">{label}</span>}
      <Icon className="scroll-button__icon" name={iconName} />
    </button>
  );
};

ScrollButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  speed: PropTypes.number,
  targetElement: PropTypes.string.isRequired,
  iconName: PropTypes.string,
  ariaLabel: PropTypes.string,
};

ScrollButton.propTypesMeta = 'exclude';

export default ScrollButton;
