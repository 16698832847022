import React from 'react';
import PropTypes from 'prop-types';
import RichText from 'components/rich-text';
import cn from 'classnames';

const SourcesAndLinksBlock = ({ heading, className, textHtml }) => {
  return (
    <div
      className={cn('sources-and-links-block', {
        [className]: className,
      })}
    >
      <div className="sources-and-links-block__container">
        {heading && (
          <span className="sources-and-links-block__heading">{heading}</span>
        )}
        <RichText text={textHtml} className="rich-text_in-sources-and-links" />
      </div>
    </div>
  );
};

SourcesAndLinksBlock.propTypes = {
  heading: PropTypes.string,
  className: PropTypes.string,
  textHtml: PropTypes.string,
};

SourcesAndLinksBlock.propTypesMeta = {
  className: 'exclude',
};

export default SourcesAndLinksBlock;
