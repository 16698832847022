import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/icon';

const RecipeFiltersRemoveFilterButton = ({ text, onclick = () => {} }) => {
  return (
    <button className="recipe-filters__remove-filter-button" onClick={onclick}>
      <Icon
        name="close"
        className="recipe-filters__remove-filter-button-icon"
      />
      <span className="recipe-filters__remove-filter-button-text">{text}</span>
    </button>
  );
};

RecipeFiltersRemoveFilterButton.propTypes = {
  text: PropTypes.string,
  onclick: PropTypes.func,
};

RecipeFiltersRemoveFilterButton.propTypesMeta = 'exclude';

export default RecipeFiltersRemoveFilterButton;
