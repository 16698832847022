import React from 'react';
import PropTypes from 'prop-types';
import FloatingTag, { position } from 'components/floating-tag';
import cn from 'classnames';

const FloatingTags = ({ items, children, className }) => {
  return (
    <div
      className={cn('floating-tags', {
        [className]: className,
      })}
    >
      {items && items.length > 0 && (
        <ul className="floating-tags__items">
          {items.map((item, index) => {
            const isTopTag =
              item.position === position.topLeft ||
              item.position === position.topCenter ||
              item.position === position.topRight;
            return (
              <li
                className={cn(
                  `floating-tags__item floating-tags__item_${item.position}`,
                  {
                    'floating-tags__item_top': isTopTag,
                    'floating-tags__item_bottom': !isTopTag,
                  }
                )}
                key={index}
              >
                <FloatingTag {...item} />
              </li>
            );
          })}
        </ul>
      )}
      {children}
    </div>
  );
};

FloatingTags.propTypes = {
  items: PropTypes.arrayOf(PropTypes.exact(FloatingTag.propTypes)),
  children: PropTypes.node,
  className: PropTypes.string,
};

FloatingTags.propTypesMeta = {
  className: 'exclude',
};

export default FloatingTags;
