import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import IconLabel from 'components/icon-label';
import recipeMessageBus from 'js/recipe-message-bus';

export const sizes = {
  normal: 'normal',
  small: 'small',
};

const RecipeDetails = ({
  className,
  size = sizes.normal,
  cookingTime,
  difficultyLevel,
  rating,
  cookingTimeIconAriaLabel,
  difficultyLevelIconAriaLabel,
  ratingIconAriaLabel,
  useFocusableDetails = true,
  isRecipePage = false,
}) => {
  const [currentRating, setCurrentRating] = useState(rating);
  useEffect(() => {
    if (isRecipePage) {
      const token = recipeMessageBus.subscribeToNewRating((msg, data) => {
        setCurrentRating(data);
      });

      return () => {
        recipeMessageBus.unsubscribeFromNewRating(token);
      };
    }
  }, [isRecipePage]);

  const commonIconLabelProps = {
    className: 'recipe-details__item',
    size: size,
    ...(useFocusableDetails && { tabIndex: 0 }),
  };

  return (
    <div
      className={cn('recipe-details', className, {
        'recipe-details_small': size === sizes.small,
      })}
    >
      {cookingTime && (
        <IconLabel
          icon="clock"
          iconAriaLabel={cookingTimeIconAriaLabel}
          text={cookingTime}
          {...commonIconLabelProps}
        />
      )}
      {difficultyLevel && (
        <IconLabel
          icon="difficulty-level"
          iconAriaLabel={difficultyLevelIconAriaLabel}
          text={difficultyLevel}
          {...commonIconLabelProps}
        />
      )}
      {currentRating && (
        <IconLabel
          icon="star-empty"
          iconAriaLabel={ratingIconAriaLabel}
          text={currentRating}
          {...commonIconLabelProps}
        />
      )}
    </div>
  );
};

RecipeDetails.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(Object.values(sizes)),
  cookingTime: PropTypes.string,
  difficultyLevel: PropTypes.string,
  rating: PropTypes.string,
  cookingTimeIconAriaLabel: PropTypes.string,
  difficultyLevelIconAriaLabel: PropTypes.string,
  ratingIconAriaLabel: PropTypes.string,
  useFocusableDetails: PropTypes.bool,
  isRecipePage: PropTypes.bool,
};

RecipeDetails.propTypesMeta = {
  className: 'exclude',
  size: 'exclude',
  useFocusableDetails: 'exclude',
};

export default RecipeDetails;
