import React from 'react';
import Image from 'components/image';
import PoorText from 'components/poor-text';
import HeadingCropper from 'components/heading-cropper';
import PropTypes from 'prop-types';
import cn from 'classnames';

export const TeaserWidths = {
  oneThird: '1-3',
  twoThird: '2-3',
  full: 'full-width',
};

const Teaser = ({
  className,
  categories,
  teaserWidth,
  url,
  image,
  heading,
  headingTagLevel = 3,
  introHtml,
  backgroundColor,
}) => {
  const horizontalMode = teaserWidth !== TeaserWidths.oneThird;
  const fullWidthMode = teaserWidth === TeaserWidths.full;

  const imageProps = {
    ...(horizontalMode && {
      fluid: true,
    }),
  };

  const RootElement = url ? 'a' : 'div';
  const hideIntro = teaserWidth === TeaserWidths.oneThird && image;
  const HeadingTag = `h${headingTagLevel}`;

  return (
    <div
      style={{ backgroundColor }}
      className={cn('teaser', {
        teaser_horizontal: horizontalMode,
        teaser_vertical: !horizontalMode,
        'teaser_full-width': fullWidthMode,
        [className]: className,
      })}
    >
      <RootElement
        href={url}
        {...(url && {
          className: 'teaser__link',
        })}
      >
        <div className="teaser__container">
          {image && (
            <div className="teaser__media">
              <Image
                {...image}
                {...imageProps}
                className={cn('teaser__image', {
                  'image_size_teaser-horizontal': horizontalMode,
                  'image_size_teaser-vertical': !horizontalMode,
                })}
              />
            </div>
          )}
          <div className="teaser__content">
            {categories && categories.length > 0 && (
              <ul className="teaser__categories">
                {categories.map((item, index) => (
                  <li className="teaser__categories-item" key={index}>
                    {item}
                  </li>
                ))}
              </ul>
            )}
            {heading && (
              <HeadingTag
                className={cn('teaser__heading', {
                  teaser__heading_large: fullWidthMode && !introHtml && !image,
                })}
              >
                <HeadingCropper
                  text={heading}
                  maxLinesMobile={
                    className === 'teaser_in-two-columns-view' ? 6 : 2
                  }
                  maxLinesDesktop={2}
                />
              </HeadingTag>
            )}
            {introHtml && !hideIntro && (
              <PoorText
                className="teaser__text poor-text_in-teaser"
                text={introHtml}
              />
            )}
          </div>
        </div>
      </RootElement>
    </div>
  );
};

Teaser.propTypes = {
  teaserWidth: PropTypes.oneOf(Object.values(TeaserWidths)),
  categories: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.exact(Image.propTypes),
  heading: PropTypes.string.isRequired,
  headingTagLevel: PropTypes.oneOf([2, 3]),
  introHtml: PropTypes.string,
  backgroundColor: PropTypes.string.isRequired,
};

Teaser.propTypesMeta = {
  headingTagLevel: 'exclude',
  className: 'exclude',
  teaserWidth: 'exclude',
};

export default Teaser;
