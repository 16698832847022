import React from 'react';
import PropTypes from 'prop-types';
import PoorText from 'components/poor-text';
import cn from 'classnames';

const TipBlock = ({ heading, tipsHtml, place }) => {
  return (
    <div
      className={cn('tip-block', {
        [`tip-block_in-${place}`]: place,
      })}
    >
      <div className="tip-block__container">
        {heading && <span className="tip-block__heading">{heading}</span>}
        {tipsHtml && (
          <PoorText className="poor-text_in-tip-block" text={tipsHtml} />
        )}
      </div>
    </div>
  );
};

TipBlock.propTypesMeta = {
  place: 'exclude',
};

TipBlock.propTypes = {
  heading: PropTypes.string,
  tipsHtml: PropTypes.string,
  place: PropTypes.string,
};

export default TipBlock;
