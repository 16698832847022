import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const LoadMoreButton = ({ text, onClick = () => {}, className }) => {
  const handleClick = () => {
    onClick();
  };

  return (
    <button onClick={handleClick} className={cn('load-more-button', className)}>
      {text}
    </button>
  );
};

LoadMoreButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

LoadMoreButton.propTypesMeta = 'exclude';

export default LoadMoreButton;
