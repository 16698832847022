import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TagsFilterItem from 'components/tags-filter-item';
import cn from 'classnames';

const TagsFilter = ({
  name,
  items,
  // eslint-disable-next-line no-unused-vars
  onChange = ({ name, items }) => {},
  className,
}) => {
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    setSelectedItem(items.find(x => x.selected));
  }, [items]);

  const handleItemClick = clickedItem => {
    const newSelectedItem = clickedItem === selectedItem ? null : clickedItem;
    setSelectedItem(newSelectedItem);
    const currentItems = items.map(item => {
      return { ...item, selected: item === newSelectedItem };
    });
    onChange({ name: name, items: currentItems });
  };

  return (
    <ul
      className={cn('tags-filter', className, {
        'tags-filter_has-selected': selectedItem,
      })}
    >
      {items &&
        items.map(item => (
          <li
            key={item.value}
            className={cn('tags-filter__item', {
              'tags-filter__item_selected': item === selectedItem,
            })}
          >
            <TagsFilterItem
              {...item}
              selected={item === selectedItem}
              onClick={() => handleItemClick(item)}
              className="tags-filter__item-button"
            />
          </li>
        ))}
    </ul>
  );
};

TagsFilter.propTypes = {
  name: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.exact(TagsFilterItem.propTypes)),
  onChange: PropTypes.func,
  className: PropTypes.string,
};

TagsFilter.propTypesMeta = {
  className: 'exclude',
  onChange: 'exclude',
};

export default TagsFilter;
