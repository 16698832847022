import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'components/icon';
import useToggle from 'hooks/use-toggle';

const ExpandablePanel = ({
  children,
  toggleTextShow = 'Show',
  toggleTextHide = 'Hide',
  toggleElement = 'button',
}) => {
  const { isActive: panelIsOpenedInMobile, toggle: togglePanel } = useToggle(
    false
  );

  const ToggleElement = toggleElement;

  return (
    <div
      className={cn('expandable-panel', {
        'expandable-panel_opened-in-mobile': panelIsOpenedInMobile,
      })}
    >
      <ToggleElement
        className="expandable-panel__toggle"
        onClick={togglePanel}
        aria-expanded={panelIsOpenedInMobile}
      >
        <Icon className="expandable-panel__toggle-icon" name="close" />
        <span className="expandable-panel__toggle-text">
          {!panelIsOpenedInMobile ? toggleTextShow : toggleTextHide}
        </span>
      </ToggleElement>

      <div className="expandable-panel__content">{children}</div>
    </div>
  );
};

ExpandablePanel.propTypes = {
  toggleTextShow: PropTypes.string,
  toggleTextHide: PropTypes.string,
  toggleElement: PropTypes.string,
  children: PropTypes.node,
};

ExpandablePanel.propTypesMeta = 'exclude';

export default ExpandablePanel;
