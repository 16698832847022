import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import useToggle from 'hooks/use-toggle';
import useClickOutside from 'hooks/use-click-outside';
import useEscape from 'hooks/use-escape';
import RecipesFilter from 'components/recipe-filter';
import RecipeFiltersRemoveFilterButton from './recipe-filters-remove-filter-button';
import ToggleButton from 'components/toggle-button';

const recipeFiltersPanelId = 'recipe-filters-panel';

const RecipeFilters = ({
  filterHint,
  filterLabel,
  clearFiltersLabel,
  totalResultsLabel,
  totalCount,
  filters,
  // eslint-disable-next-line no-unused-vars
  onFilterChange = ({ name, items }) => {},
}) => {
  const {
    isActive: isFiltersPanelOpen,
    toggle: toggleFiltersPanel,
    deactivate,
  } = useToggle(false);
  const [selectedItemsMap, setSelectedItemsMap] = useState([]);
  const [selectedItemsCount, setSelectedItemsCount] = useState(0);
  const [filterRefs, setFilterRefs] = useState(new Map());
  const ref = React.useRef();
  useEscape(deactivate);
  useClickOutside(ref, deactivate);

  useEffect(() => {
    setSelectedItemsMap(
      new Map(filters.map(x => [x, (x.items || []).filter(i => i.selected)]))
    );

    setFilterRefs(new Map(filters.map(f => [f, createRef()])));
  }, [filters]);

  useEffect(() => {
    setSelectedItemsCount(
      [...selectedItemsMap.values()].reduce(
        (sum, items) => sum + items.length,
        0
      )
    );
  }, [selectedItemsMap]);

  const handleFilterChange = (filter, e) => {
    setSelectedItemsMap(map => {
      map.set(
        filter,
        e.items.filter(i => i.selected)
      );
      return new Map(map);
    });
    onFilterChange(e);
  };

  const unselectFilterItem = (filter, item) => {
    filterRefs.get(filter).current.unselectItem(item);
  };

  const unselectAll = () => {
    [...filterRefs.values()].forEach(fr => fr.current.unselectAll());
  };

  const getSelectedItemsMapAsFilterItemEntries = () => {
    return [...selectedItemsMap.entries()].flatMap(([filter, items]) =>
      items.map(item => ({ filter, item }))
    );
  };

  return (
    <div className="recipe-filters" ref={ref}>
      <ToggleButton
        hint={filterHint}
        isActive={isFiltersPanelOpen}
        onClick={toggleFiltersPanel}
        label={filterLabel}
        amount={selectedItemsCount}
        ariaControls={recipeFiltersPanelId}
      />
      <div
        className={cn('recipe-filters__panel', {
          'recipe-filters__panel_has-selected': selectedItemsCount > 0,
          'recipe-filters__panel_opened': isFiltersPanelOpen,
        })}
        id={recipeFiltersPanelId}
        aria-hidden={!isFiltersPanelOpen}
      >
        <div className="recipe-filters__panel-container">
          {selectedItemsCount > 0 && (
            <React.Fragment>
              <button
                className="recipe-filters__clear-filters-button"
                onClick={unselectAll}
              >
                {clearFiltersLabel}
              </button>
              <span className="recipe-filters__selected-filters-heading">
                {totalResultsLabel} {totalCount}
              </span>
              <div className="recipe-filters__selected-filters">
                {getSelectedItemsMapAsFilterItemEntries().map(
                  (entry, index) => (
                    <RecipeFiltersRemoveFilterButton
                      onclick={() =>
                        unselectFilterItem(entry.filter, entry.item)
                      }
                      key={index}
                      text={entry.item.text}
                    />
                  )
                )}
              </div>
            </React.Fragment>
          )}

          {filters && (
            <div className="recipe-filters__filters">
              {filters.map((filter, index) => {
                return (
                  <div className="recipe-filters__filter" key={index}>
                    <RecipesFilter
                      ref={filterRefs.get(filter)}
                      {...filter}
                      onChange={e => handleFilterChange(filter, e)}
                    />
                  </div>
                );
              })}
            </div>
          )}

          {selectedItemsCount > 0 && (
            <button
              className="recipe-filters__clear-filters-button recipe-filters__clear-filters-button_bottom"
              onClick={unselectAll}
            >
              {clearFiltersLabel}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

RecipeFilters.propTypes = {
  filterHint: PropTypes.string,
  filterLabel: PropTypes.string,
  clearFiltersLabel: PropTypes.string,
  totalResultsLabel: PropTypes.string,
  totalCount: PropTypes.number,
  selectedFilters: PropTypes.arrayOf(
    PropTypes.exact(RecipeFiltersRemoveFilterButton.propTypes)
  ),
  filters: PropTypes.arrayOf(PropTypes.exact(RecipesFilter.propTypes)),
  onFilterChange: PropTypes.func,
};

RecipeFilters.propTypesMeta = 'exclude';

export default RecipeFilters;
