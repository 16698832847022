import React from 'react';
import PropTypes from 'prop-types';
import Scrollbar from 'react-scrollbars-custom';
import * as Components from '../../app.components';

const Carousel = ({ items, itemComponentName }) => {
  const Item = Components[itemComponentName];
  if (!Item) {
    // eslint-disable-next-line no-console
    console.error(`Components.${itemComponentName} is not defined`);
    return null;
  }
  return items ? (
    <div className="carousel">
      <div className="carousel__container">
        <Scrollbar
          style={{ width: '100%' }}
          translateContentSizeYToHolder={true}
          noScrollY={true}
          trackXProps={{ className: 'carousel__scrollbar-track' }}
          thumbXProps={{ className: 'carousel__scrollbar-thumb' }}
        >
          <div className="carousel__items">
            {items.map((item, index) => {
              return (
                <div key={itemComponentName + index} className="carousel__item">
                  <Item {...item} className="carousel__teaser" />
                </div>
              );
            })}
          </div>
        </Scrollbar>
      </div>
    </div>
  ) : null;
};

Carousel.propTypes = {
  itemComponentName: PropTypes.oneOf(['Teaser', 'RecipeTeaser']),
  items: PropTypes.array,
};

Carousel.propTypesMeta = 'exclude';

export default Carousel;
