import React from 'react';
import PropTypes from 'prop-types';
import pluralOrSingularText from 'js/pluralOrSingularText.js';

const IngredientName = ({ amount, plural, singular }) => {
  let name = pluralOrSingularText(amount, plural, singular);

  return name && <span className="ingredients__section-item-name">{name}</span>;
};

IngredientName.propTypes = {
  amount: PropTypes.number,
  plural: PropTypes.string,
  singular: PropTypes.string,
};

IngredientName.propTypesMeta = 'exclude';

export default IngredientName;
