import React from 'react';
import PropTypes from 'prop-types';

const BreadcrumbsLink = ({ text, url, ariaCurrent }) => {
  return (
    <a href={url} aria-current={ariaCurrent} className="breadcrumbs__link">
      {text}
    </a>
  );
};

BreadcrumbsLink.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
  ariaCurrent: PropTypes.string,
};

BreadcrumbsLink.propTypesMeta = {
  ariaCurrent: 'exclude',
};

export default BreadcrumbsLink;
