import React from 'react';
import PropTypes from 'prop-types';
import useBreakpoints from 'hooks/use-breakpoints';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

const HeadingCropper = ({ text, maxLinesMobile, maxLinesDesktop }) => {
  const { isDesktop } = useBreakpoints(true);
  return (
    text && (
      <ResponsiveEllipsis
        component="span"
        text={text}
        maxLine={isDesktop ? maxLinesDesktop : maxLinesMobile}
        ellipsis="..."
        trimRight
        basedOn="letters"
      />
    )
  );
};

HeadingCropper.propTypes = {
  text: PropTypes.string,
  maxLinesMobile: PropTypes.number.isRequired,
  maxLinesDesktop: PropTypes.number.isRequired,
};

HeadingCropper.propTypesMeta = 'exclude';

export default HeadingCropper;
