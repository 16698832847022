/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/link';
import cn from 'classnames';
import Icon from 'components/icon';

const ColoredTag = ({
  text,
  url,
  openInNewTab,
  className,
  color,
  linkTabIndex,
  isActive,
}) => {
  var tagStyle = {
    backgroundColor: color,
  };

  let Component = url ? 'a' : 'div';

  return (
    <Component
      href={url ? url : null}
      target={openInNewTab ? '_blank' : null}
      rel={openInNewTab ? 'nofollow noopener' : null}
      className={cn('colored-tag', className, {
        'colored-tag_with-link': url,
        'colored-tag_active': isActive,
      })}
      style={tagStyle}
      tabIndex={linkTabIndex ? linkTabIndex : null}
    >
      {isActive && <Icon name="check" className="colored-tag__icon" />}
      <span className="colored-tag__text">{text}</span>
    </Component>
  );
};

ColoredTag.propTypes = Object.assign({}, Link.propTypes, {
  color: PropTypes.string,
  linkTabIndex: PropTypes.number,
  isActive: PropTypes.bool,
});

ColoredTag.propTypesMeta = {
  linkTabIndex: 'exclude',
};

export default ColoredTag;
