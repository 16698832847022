import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Checkbox = ({
  checked,
  name,
  value,
  label,
  id,
  onChange = () => {},
  disabled,
  className,
}) => {
  const [isChecked, setisChecked] = React.useState(checked);

  const handleCange = () => {
    setisChecked(!isChecked);
    onChange(!isChecked);
  };

  React.useEffect(() => {
    setisChecked(checked);
  }, [checked]);

  return (
    <label
      className={cn('checkbox', className, {
        checkbox_disabled: disabled,
      })}
    >
      <input
        className="checkbox__input"
        type="checkbox"
        name={name}
        onChange={e => handleCange(e)}
        defaultChecked={checked}
        defaultValue={value}
        id={id}
        disabled={disabled}
      />
      <div
        className={cn('checkbox__checkmark', {
          checkbox__checkmark_checked: isChecked,
        })}
      ></div>
      <div className="checkbox__label">{label}</div>
    </label>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

Checkbox.propTypesMeta = 'exclude';

export default Checkbox;
