import React from 'react';
import PropTypes from 'prop-types';
import RichText from 'components/rich-text';
import cn from 'classnames';

const RichTextBlock = ({ textHtml, place }) => {
  return (
    <div
      className={cn('rich-text-block', {
        [`rich-text-block_in-${place}`]: place,
      })}
    >
      <RichText text={textHtml} className="rich-text-block__content" />
    </div>
  );
};

RichTextBlock.propTypesMeta = {
  place: 'exclude',
};

RichTextBlock.propTypes = {
  textHtml: PropTypes.string,
  place: PropTypes.string,
};

export default RichTextBlock;
