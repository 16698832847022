import React from 'react';
import PropTypes from 'prop-types';

const IngredientAmount = ({ number }) => {
  return number && number !== 0 ? (
    <span className="ingredients__section-item-amount">{number}</span>
  ) : null;
};

IngredientAmount.propTypes = {
  number: PropTypes.number,
};

IngredientAmount.propTypesMeta = 'exclude';

export default IngredientAmount;
