import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ShareLink from 'components/models/share-link';
import Icon from 'components/icon';
import cn from 'classnames';
import isRunningOnClient from '@creuna/utils/is-running-on-client';

const SharePanel = ({ heading, shareLinks, lang, className }) => {
  const [linkCopiedMessageVisible, setlinkCopiedMessageVisible] = useState(
    false
  );
  const printPage = () => {
    isRunningOnClient && window.print();
  };

  useEffect(() => {
    if (linkCopiedMessageVisible) {
      setTimeout(() => {
        setlinkCopiedMessageVisible(false);
      }, 4000);
    }
  }, [linkCopiedMessageVisible]);

  const copyLinkToClipboard = () => {
    if (isRunningOnClient) {
      try {
        const link = window.location.href;
        const el = document.createElement('textarea');
        el.value = link;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        if (!linkCopiedMessageVisible) {
          setlinkCopiedMessageVisible(true);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('link copying failed');
      }
    }
  };

  return (
    <div className={cn('share-panel', { [className]: className })}>
      {heading && <span className="share-panel__heading">{heading}</span>}
      <div className="share-panel__container">
        <ul className="share-panel__list">
          {shareLinks &&
            shareLinks.map((item, index) => {
              return (
                <li className="share-panel__item" key={index}>
                  <a
                    href={item.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="share-panel__button"
                    aria-label={item.label}
                  >
                    <img
                      src={item.icon.src}
                      alt={item.icon.alt}
                      className="share-panel__icon"
                    />
                  </a>
                </li>
              );
            })}
          <li className="share-panel__item">
            <button
              className="share-panel__button"
              onClick={copyLinkToClipboard}
              aria-label={lang.copyLinkToClipboardLabel}
            >
              <Icon className="share-panel__icon" name="copy-link" />
            </button>
          </li>
          <li className="share-panel__item">
            <button
              className="share-panel__button"
              onClick={printPage}
              aria-label={lang.printPageLabel}
            >
              <Icon className="share-panel__icon" name="print" />
            </button>
          </li>
        </ul>
        <span
          className={cn('share-panel__link-copied-message', {
            'share-panel__link-copied-message_visible': linkCopiedMessageVisible,
          })}
        >
          {lang.linkCopiedMessage}
        </span>
      </div>
    </div>
  );
};

SharePanel.propTypes = {
  heading: PropTypes.string,
  shareLinks: PropTypes.arrayOf(PropTypes.exact(ShareLink.propTypes)),
  lang: PropTypes.shape({
    linkCopiedMessage: PropTypes.string.isRequired,
    copyLinkToClipboardLabel: PropTypes.string.isRequired,
    printPageLabel: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
};

export default SharePanel;
