import React from 'react';
import PropTypes from 'prop-types';
import RichText from 'components/rich-text';
import cn from 'classnames';

const AreaBlock = ({ heading, textHtml, className }) => {
  return (
    <div
      className={cn('area-block', {
        [className]: className,
      })}
    >
      <div className="area-block__container">
        {heading && <h2 className="area-block__heading">{heading}</h2>}
        <RichText text={textHtml} className="area-block__text" />
      </div>
    </div>
  );
};

AreaBlock.propTypes = {
  heading: PropTypes.string,
  textHtml: PropTypes.string,
  className: PropTypes.string,
};

AreaBlock.propTypesMeta = {
  className: 'exclude',
};

export default AreaBlock;
