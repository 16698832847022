import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import FocusTrap from 'focus-trap-react';

const ModalComponent = ({
  children,
  isOpened = false,
  titleAriaLabel,
  id,
  onClose = () => {},
}) => {
  const onKeyUp = e => {
    if (e.key === 'Escape') {
      onClose();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', onKeyUp);
  }, []);

  return (
    <Modal
      isOpen={isOpened}
      closeTimeoutMS={500}
      ariaHideApp={false}
      className="modal"
      aria={{
        label: titleAriaLabel,
      }}
      id={id}
    >
      <FocusTrap isActive={isOpened}>
        <div className="modal__container">
          <button onClick={onClose} className="modal__close-btn"></button>
          {children}
        </div>
      </FocusTrap>
    </Modal>
  );
};

ModalComponent.propTypes = {
  isOpened: PropTypes.bool,
  children: PropTypes.node,
  onClose: PropTypes.func,
  titleAriaLabel: PropTypes.string,
  id: PropTypes.string,
};

ModalComponent.propTypesMeta = 'exclude';

export default ModalComponent;
