import React from 'react';
import PropTypes from 'prop-types';
import BreadcrumbsLink from './breadcrumbs-link';
import cn from 'classnames';

const Breadcrumbs = ({
  links,
  className,
  lang = { ariaLabel: 'Breadcrumb' },
}) => {
  return (
    <nav className={cn('breadcrumbs', className)} aria-label={lang.ariaLabel}>
      {links && (
        <ol className="breadcrumbs__items">
          {links.map((link, i) => (
            <li key={i} className="breadcrumbs__item">
              <BreadcrumbsLink
                {...link}
                ariaCurrent={i === links.length - 1 ? 'page' : null}
              />
            </li>
          ))}
        </ol>
      )}
    </nav>
  );
};

Breadcrumbs.propTypes = {
  lang: PropTypes.shape({
    ariaLabel: PropTypes.string,
  }),
  links: PropTypes.arrayOf(PropTypes.exact(BreadcrumbsLink.propTypes)),
  className: PropTypes.string,
};

Breadcrumbs.propTypesMeta = {
  className: 'exclude',
};

export default Breadcrumbs;
