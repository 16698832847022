import React, { useRef, useState, useEffect, useMemo } from 'react';
import ReactPaginate from 'react-paginate';
import cn from 'classnames';
import PropTypes from 'prop-types';
import useDidUpdateEffect from 'hooks/use-did-update-effect';
import stringHelper from 'js/string-helper';
import PagerLang from 'components/models/pager-lang/pager-lang';

const Pager = ({
  totalPages,
  page,
  pageRangeDisplayed = 2,
  className,
  lang = {
    prevLabel: 'Prev',
    pageLabel: '{0} of {1}',
    nextLabel: 'Next',
    jumpBackwardAriaLabel: 'Jump backward',
    jumpForwardAriaLabel: 'Jump forward',
  },
  onPageChange,
}) => {
  const pagerSelectRef = useRef(null);
  const pagerWrapperRef = useRef(null);
  const pagerRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(page);

  useDidUpdateEffect(() => {
    setCurrentPage(page);
  }, [page]);

  const pagerSelectOptions = useMemo(() => {
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  }, [totalPages]);

  useEffect(() => {
    if (
      pagerWrapperRef &&
      pagerWrapperRef.current &&
      pagerSelectRef &&
      pagerSelectRef.current &&
      totalPages
    ) {
      const firstPagerItem = pagerWrapperRef.current.querySelector('ul > li');
      firstPagerItem.parentNode.insertBefore(
        pagerSelectRef.current,
        firstPagerItem.nextSibling
      );
    }
  }, [pagerSelectRef, pagerWrapperRef, totalPages]);

  const onMobileSelectorChange = e => {
    const newPage = +e.target.value;
    setCurrentPage(newPage);
    onPageChange(newPage);

    if (pagerRef && pagerRef.current) {
      pagerRef.current.handlePageChange(newPage - 1);
    }
  };

  const onPagerPageChange = ({ selected }) => {
    const newPage = selected + 1;
    setCurrentPage(newPage);
    onPageChange(newPage);
  };

  return totalPages > 1 ? (
    <div
      className={cn('pager', {
        [className]: className,
      })}
    >
      {pagerSelectOptions && pagerSelectOptions.length > 0 && (
        <li ref={pagerSelectRef}>
          <select
            className="pager__mobile-selector"
            value={currentPage}
            onChange={onMobileSelectorChange}
          >
            {pagerSelectOptions.map(option => (
              <option key={option} value={option}>
                {stringHelper.format(lang.pageLabel, option, totalPages)}
              </option>
            ))}
          </select>
        </li>
      )}
      <div className="pager__wrapper" ref={pagerWrapperRef}>
        <ReactPaginate
          ref={pagerRef}
          pageCount={totalPages}
          forcePage={currentPage - 1}
          previousLabel={lang.prevLabel}
          nextLabel={lang.nextLabel}
          ariaLabelBuilder={pageIndex =>
            stringHelper.format(lang.pageLabel, pageIndex, totalPages)
          }
          breakAriaLabels={{
            forward: lang.jumpBackwardAriaLabel,
            backward: lang.jumpForwardAriaLabel,
          }}
          hrefAllControls={true}
          breakClassName="pager__break"
          disableInitialCallback={true}
          pageRangeDisplayed={pageRangeDisplayed}
          marginPagesDisplayed={1}
          containerClassName="pager__list"
          pageClassName="pager__item"
          pageLinkClassName="pager__link"
          previousClassName="pager__prev"
          nextClassName="pager__next"
          onPageChange={onPagerPageChange}
        />
      </div>
    </div>
  ) : null;
};

Pager.propTypesMeta = 'exclude';

Pager.propTypes = {
  className: PropTypes.string,
  pageRangeDisplayed: PropTypes.number,
  page: PropTypes.number,
  totalPages: PropTypes.number,
  lang: PropTypes.shape(PagerLang.propTypes).isRequired,
  onPageChange: PropTypes.func,
};

export default Pager;
