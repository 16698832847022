/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import LinkBase from 'components/models/link-base';
import cn from 'classnames';

const Link = ({
  className,
  openInNewTab = false,
  url,
  text,
  tabindex,
  inlineStyle,
  onFocus = () => {},
  onBlur = () => {},
}) => (
  <a
    href={url}
    className={cn('link', {
      [className]: className,
    })}
    target={openInNewTab ? '_blank' : null}
    rel={openInNewTab ? 'nofollow noopener' : null}
    tabIndex={tabindex}
    style={inlineStyle}
    onFocus={onFocus}
    onBlur={onBlur}
  >
    {text}
  </a>
);

Link.propTypesMeta = {
  className: 'exclude',
  tabindex: 'exclude',
  inlineStyle: 'exclude',
  onFocus: 'exclude',
  onBlur: 'exclude',
};

Link.propTypes = Object.assign({}, LinkBase.propTypes, {
  className: PropTypes.string,
  inlineStyle: PropTypes.object,
  tabindex: PropTypes.number,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
});

export default Link;
