import React, { useState, useCallback, useRef, useMemo } from 'react';
import Modal from 'components/modal';
import Image from 'components/image';
import useToggle from '../../hooks/use-toggle';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import cn from 'classnames';
import idHelper from 'js/id-helper';

const Video = ({
  src,
  autoplay = false,
  className,
  previewImage,
  imageSettings = {},
  fluid,
  playButtonTabIndex,
  modalTitleAriaLabel = 'Modalvindu for videoavspiller',
}) => {
  const uniqueVideoModalId = useMemo(
    () => idHelper.generateUniqueId('video'),
    []
  );
  const { isActive, deactivate, activate } = useToggle(false);
  const [playing, setPlaying] = useState(false);
  const playerContainerRef = useRef();

  const onPlayBtnClick = e => {
    e.preventDefault();
    e.stopPropagation();
    activate();
    if (autoplay) {
      setPlaying(true);
    }
  };

  const onPlayerReady = useCallback(() => {
    if (playerContainerRef && playerContainerRef.current) {
      const youtubeIframe = playerContainerRef.current.querySelector('iframe');

      if (youtubeIframe) {
        youtubeIframe.setAttribute('tabindex', '0');
      }
    }
  }, [playerContainerRef]);

  return (
    <div
      className={cn('video', {
        video_fluid: fluid,
        [className]: className,
      })}
    >
      {previewImage && (
        <Image
          className={cn('video__image', {
            [imageSettings.className]: imageSettings && imageSettings.className,
          })}
          {...previewImage}
          {...imageSettings}
        />
      )}
      <div className="video__play-btn-holder">
        <button
          className="video__play-btn"
          onClick={onPlayBtnClick}
          aria-haspopup="dialog"
          aria-controls={uniqueVideoModalId}
          {...(playButtonTabIndex !== undefined && {
            tabIndex: playButtonTabIndex,
          })}
        ></button>
      </div>
      <Modal
        isOpened={isActive}
        onClose={deactivate}
        titleAriaLabel={modalTitleAriaLabel}
        id={uniqueVideoModalId}
      >
        <div className="video__modal-holder">
          <div className="video__container" ref={playerContainerRef}>
            <ReactPlayer
              playing={playing}
              url={src}
              controls={true}
              onReady={onPlayerReady}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

Video.propTypes = {
  previewImage: PropTypes.exact(Image.propTypes),
  src: PropTypes.string,
  autoplay: PropTypes.bool,
  className: PropTypes.string,
  fluid: PropTypes.bool,
  imageSettings: PropTypes.shape({
    className: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    fluid: PropTypes.bool,
  }),
  playButtonTabIndex: PropTypes.string,
  modalTitleAriaLabel: PropTypes.string,
};

Video.propTypesMeta = {
  className: 'exclude',
  imageSettings: 'exclude',
  fluid: 'exclude',
  playButtonTabIndex: 'exclude',
};

export default Video;
