import React from 'react';
import PropTypes from 'prop-types';
import pluralOrSingularText from 'js/pluralOrSingularText.js';

const IngredientUnit = ({ amount, plural, singular }) => {
  let unitText = pluralOrSingularText(amount, plural, singular);

  return (
    unitText && (
      <span className="ingredients__section-item-unit">{unitText}</span>
    )
  );
};

IngredientUnit.propTypes = {
  amount: PropTypes.number,
  plural: PropTypes.string,
  singular: PropTypes.string,
};

IngredientUnit.propTypesMeta = 'exclude';

export default IngredientUnit;
