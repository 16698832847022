import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const DictionaryBlock = ({
  heading,
  className,
  hintText,
  items,
  backgroundColor,
}) => {
  return (
    <div
      className={cn('dictionary-block', {
        [className]: className,
      })}
      style={{ backgroundColor }}
    >
      <div className="dictionary-block__header">
        {heading && <h2 className="dictionary-block__heading">{heading}</h2>}
        {hintText && <span className="dictionary-block__hint">{hintText}</span>}
      </div>
      <div className="dictionary-block__rows">
        {items &&
          items.length > 0 &&
          items.map((item, index) => (
            <div className="dictionary-block__row" key={index}>
              <span>{item.label}</span>
              <span className="dictionary-block__value">{item.value}</span>
            </div>
          ))}
      </div>
    </div>
  );
};

DictionaryBlock.propTypes = {
  heading: PropTypes.string,
  hintText: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
};

DictionaryBlock.propTypesMeta = {
  className: 'exclude',
};

export default DictionaryBlock;
